import { IAugmentedJQuery, IComponentOptions } from 'angular';
import fromPairs from 'lodash/fromPairs';
import NgComponent from 'ngcomponent';
import * as React from 'react';
import { Root, createRoot } from 'react-dom/client';
/**
 * Wraps a React component in Angular. Returns a new Angular component.
 *
 * Usage:
 *
 *   ```ts
 *   type Props = { foo: number }
 *   class ReactComponent extends React.Component<Props, S> {}
 *   const AngularComponent = react2angular(ReactComponent, ['foo'])
 *   ```
 */
export function react2angular<Props extends { [k: string]: unknown }>(
  Class: React.ComponentType<Props>,
  bindingNames: (keyof Props)[] | null = null,
  injectNames: string[] = []
): IComponentOptions {
  const names =
    bindingNames ||
    (Class.propTypes && (Object.keys(Class.propTypes) as (keyof Props)[])) ||
    [];

  return {
    bindings: fromPairs(names.map((_) => [_, '<'])),
    controller: [
      '$element',
      ...injectNames,
      class extends NgComponent<Props> {
        static get $$ngIsClass() {
          return true;
        }

        injectedProps: { [name: string]: unknown };
        root: Root;

        constructor($element: IAugmentedJQuery, ...injectedProps: unknown[]) {
          super();

          this.injectedProps = {};
          this.root = createRoot($element[0] as Element);
          injectNames.forEach((name, i) => {
            this.injectedProps[name] = injectedProps[i];
          });
        }
        render() {
          const componentProps = {
            ...this.props,
            ...this.injectedProps
          } as Props;
          this.root.render(React.createElement(Class, componentProps));
        }
        componentWillUnmount() {
          this.root.unmount();
        }
      }
    ]
  };
}
