import { BusinessDomainConnectionTypes } from '../../constants/businessDomainConnectionTypes';
import IUserSummary from './userSummary';

export default interface IUserImportRequest {
  user: Partial<IUserSummary>;
  isDeleted?: boolean;
  manager: Partial<IUserSummary>;
  connectionType: BusinessDomainConnectionTypes;
  requestStatus?: UserImportRequestStatus;
  requestMessage?: string;
}

export enum UserImportRequestStatus {
  PENDING = 0,
  SKIPPED = 1,
  FAILED = 400,
  FAILED_DUPLICATE_ROW = 401,
  FAILED_BAD_EMAIL = 402,
  FAILED_MANAGER_MISSING = 410,
  FAILED_MISSING_HEADERS = 500,
  FAILED_BAD_HEADER = 501,
  FAILED_BAD_CONNECTION_TYPE = 505,
  DONE = 100
}
