import angular from 'angular';
import kebabCase from 'lodash/kebabCase';

import { getInjector } from './serviceInjector';

import React, { useEffect, useMemo, useState } from 'react';

interface Scope<Props> extends angular.IScope {
  props: Props;
}

/**
 * Wraps an Angular component in React. Returns a new React component.
 *
 * Usage:
 *
 *   ```ts
 *   const Bar = { bindings: {...}, template: '...', ... }
 *
 *   angular
 *     .module('foo', [])
 *     .component('bar', Bar)
 *
 *   type Props = {
 *     onChange(value: number): void
 *   }
 *
 *   const Bar = angular2react<Props>('bar', Bar)
 *
 *   <Bar onChange={...} />
 *   ```
 */

/**
 * Higher order function to create a react component wrapping an angularJs component
 * @param componentName
 * @param component
 * @returns React component
 */
export const angular2react = <Props extends object>(
  componentName: string,
  component: angular.IComponentOptions
): React.FC<Props> => {
  /**
   * angularJs bindings with react props
   * @example: {"selected-node":"props.selectedNode","dynamic-height":"props.dynamicHeight"}
   **/
  const bindings: { [key: string]: string } = {};
  if (component.bindings) {
    for (const binding in component.bindings) {
      bindings[kebabCase(binding)] = `props.${binding}`;
    }
  }

  /**
   * react component wrapping the angularJs component
   */
  const reactComponent = (props: Props) => {
    const [scope] = useState<Scope<Props>>(() => {
      const newScope = getInjector().get('$rootScope').$new(true);
      return Object.assign(newScope, {
        props
      });
    });

    /**
     * destroy the scope when component unmount
     */
    useEffect(() => {
      return () => {
        scope.$destroy();
      };
    }, []);

    /**
     * set props to scope inside angularJS context so UI will change automatically
     */
    useEffect(() => {
      scope.$apply(() => (scope.props = props));
    }, [props]);

    /**
     * link the created scope and the angularJS component together via $compile
     */
    const compile = (element: HTMLElement) => {
      /**
       * after unmounted dont know why compile still called
       * causing cause Error: $rootScope:inprog Action Already In Progress
       * element is null after mounted so exit
       */
      if (!element) {
        return;
      }
      getInjector().get('$compile')(element)(scope);
    };

    /**
     * react component doenst need to render so cached it for better performance
     * only need to ask angularJs component to re-render when props changes
     */
    const cachedReactElem = useMemo(() => {
      return React.createElement(kebabCase(componentName), {
        ...bindings,
        ref: compile
      });
    }, []);

    return cachedReactElem;
  };

  return reactComponent;
};
